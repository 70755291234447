import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Input } from "@mui/material";
import { DataGridPro, csCZ, GridToolbarProps, GridEditRowsModel, GridColumns, GridCallbackDetails, GridCellParams, GridRowId, GridRowParams, GridToolbar, MuiBaseEvent, MuiEvent, visibleGridColumnsSelector, useGridApiRef, gridVisibleSortedRowIdsSelector, gridVisibleRowCountSelector, visibleGridColumnsLengthSelector, GridToolbarFilterButton, GridToolbarDensitySelector, GridRowModelUpdate, GridRowsProp, GridEditCellValueParams, GridValueFormatterParams, GridEditCellPropsParams, GridCellEditCommitParams, GridActionsCellItem, GridEventListener, GridEvents, GridToolbarContainer, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { getLicense } from './../../features/Licence';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import 'react-phone-input-2/lib/style.css';
import { Divider, Menu } from '@mui/material';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import axios from "axios";
import { styled } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import MuiGrid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { login, loginData } from "../Login/redux/loginSlice";
import { loadingLogin, loginLoadingData } from "../Login/redux/loginLoadingSlice";
import { usersUrl } from "../../features/urlHelper";

import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBack from "@mui/icons-material/ArrowBack";



function App() {
    LicenseInfo.setLicenseKey(getLicense());
    const theme = createTheme();
    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

    const success_delete = () => toast.success("Použivatel byl odstranen!");
    const success_save = () => toast.success("Použivatel byl přidán!");
    const success_edit = () => toast.success("Použivatel byl upraven!");

    const [anchorEl_admin, setAnchorEl_admin] = useState(null);
    const open_admin = Boolean(anchorEl_admin);

    const handleClose_admin = () => {
        setAnchorEl_admin(null);
    };

    const handleClick_admin = (event) => {
        setAnchorEl_admin(event.currentTarget);
    };

    const apiRef = useGridApiRef()
    const navigate = useNavigate();

    const ldata = useSelector(loginData);
    const loldata = useSelector(loginLoadingData);

    const [usersData, setUsersData] = useState([]);
    const [muiTableKey, setMuiTableKey] = React.useState(0);
    const [edit_dialog_header, setEdit_dialog_header] = useState("");

    const [password_valid, setPassword_valid] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [error_dialog, setError_dialog] = React.useState("");

    const [open_edit, setOpen_edit] = React.useState(false);
    const [open_delete, setOpen_delete] = React.useState(false);

    const [userData, setUserData] = useState({
        id: 0,
        username: "",
        password: "",
        new_password: "",
        telefon: "",
        typ: "V",
        stredisko: "",
        admin: false
    })

    useEffect(() => {
        console.log(ldata)
        getDataUsers()
    }, []);


    async function getDataUsers() {
        await axios.get(usersUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                // vymazanie filtra
                setMuiTableKey(muiTableKey + 1);
                setUsersData(response.data)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    function NoRowsOverlay() {
        return (
            <Stack height="100%" alignItems="center" justifyContent="center">
            </Stack>
        );
    };

    function CustomToolbarEmpty() {
        return (
            <div>

            </div>
        );
    }


    function EditToolbar(props) {

        const handleClick = () => {
            setEdit_dialog_header("Přidat uživatele")
            setUserData({
                id: 0,
                username: "",
                password: "",
                new_password: "",
                telefon: "",
                typ: "V",
                stredisko: "",
                admin: false
            })

            setOpen_edit(true);
        };

        return (
            <GridToolbarContainer>

                <Button color="primary" startIcon={<PersonAddAltIcon />} onClick={handleClick}>
                    Přidat použivatele
                </Button>
            </GridToolbarContainer>
        );
    }
    function password_validation(password1, password2) {

        if (password1 === password2) {
            setPassword_valid(true)
        }
        else {
            setPassword_valid(false)
        }
        if (password2 === "" || password1 === "") {
            setPassword_valid(true)
        }



    };

    const StyledBox = styled(Box)(({ theme }) => ({
        height: 500,
        width: "100%",
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        '& .cell-status-1': {
            backgroundColor: '#ffcccb',
        },
        '& .cell-status-2': {
            backgroundColor: 'yellow',
        },
        '& .cell-status-3': {
            backgroundColor: '#90EE90',
        },
        '& .row-status-3S': {
            backgroundColor: '#FAFAFA',
            color: '#ABB0B8'
        },
        '& .row-status-2V': {
            backgroundColor: '#FAFAFA',
            color: '#ABB0B8'
        },

    }));

    const columns = [
        { field: 'id', headerName: 'Id', type: "number", editable: true, width: 60, pinnable: false, headerAlign: "center", },
        { field: 'username', headerName: 'Přihlašovací jméno', editable: true, width: 140, pinnable: false, headerAlign: "center", },

        {
            field: 'password',
            headerName: 'Heslo',
            headerAlign: "center",
            width: 100,
            //editable: true,
            type: 'actions',
            pinnable: false,
            getActions: (params) => {
                return [
                    <div>*******</div>

                ]

            }


        },
        {
            field: 'telefon',
            headerName: 'Tel. cislo',
            headerAlign: "center",
            width: 150,
            editable: true,
            pinnable: false,


        },

        {
            field: 'stredisko',
            headerName: 'Středisko',
            headerAlign: "center",
            width: 150,
            editable: true,
            pinnable: false,

        },

        {
            field: 'typs',
            headerName: 'Typ uživatele',
            headerAlign: "center",
            width: 100,
            editable: true,
            pinnable: false,

        },
        {
            field: 'admin',
            headerName: 'Admin',
            headerAlign: "center",
            type: 'boolean',
            width: 100,
            editable: true,
            pinnable: false,

        },
        {
            field: 'Edituj',
            headerAlign: "center",
            width: 80,
            pinnable: true,
            renderCell: (params) => (
                <>
                    <Button onClick={() => {
                        setUserData({
                            id: params.row.id,
                            username: params.row.username,
                            password: "",
                            new_password: "",
                            telefon: params.row.telefon,
                            typ: params.row.typ,
                            stredisko: params.row.stredisko,
                            admin: params.row.admin
                        })

                        setOpen_edit(true);
                        setEdit_dialog_header("Úprava uživatele")
                    }}>
                        <ModeEditOutlineIcon />
                    </Button>

                </>
            ),
        },
        {
            field: 'Smaž',
            headerAlign: "center",
            width: 80,
            pinnable: true,
            renderCell: (params) => (
                <>
                    <Button color="error" onClick={() => {
                        setOpen_delete(true)
                        setUserData({ ...userData, id: params.row.id, username: params.row.username })
                        //handleDeleteClick(params.row)
                    }}>
                        <DeleteIcon />
                    </Button>

                </>
            ),
        },



    ];

    return (
        <div style={{ minWidth: 300 }}>

            <AppBar >
                <Toolbar>
                    {

                    }
                    <Typography variant="h6" component="p" sx={{ flexGrow: 1 }}>
                        <Button onClick={() => { navigate('/vysyp') }} style={{ color: "white" }}><ArrowBack fontSize="large" /></Button>  {loldata ? "" : ldata.UserData.userinfo}
                    </Typography>
                    {ldata.UserData.admin
                        ? <>   <Button
                            sx={{ color: "white", textTransform: "none", fontWeight: 'bold' }}
                            id="fade-button"
                            aria-controls={open_admin ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open_admin ? 'true' : undefined}
                            onClick={handleClick_admin}
                        >
                            Admin
                        </Button>
                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl_admin}
                                open={open_admin}
                                onClose={handleClose_admin}
                                TransitionComponent={Fade}
                            >
                                <MenuItem >Seznam uživatelů</MenuItem>

                            </Menu></>
                        : <></>
                    }


                    <Button color="inherit" onClick={() => {
                        navigate('/')
                    }}>Odhlášení</Button>
                </Toolbar>
            </AppBar>

            <Box>
                <ThemeProvider theme={theme}>
                    <Container component="main" >
                        <CssBaseline />
                        <Box
                            sx={{
                                height: height,
                                width: "100%",
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                sx={{ height: height - 200, width: width }}
                            >
                                <StyledBox>
                                    <DataGridPro
                                        //localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
                                        apiRef={apiRef}
                                        components={{
                                            Toolbar: usersData.length === 0 ? CustomToolbarEmpty : EditToolbar,
                                            NoRowsOverlay

                                        }}
                                        componentsProps={{
                                            toolbar: { apiRef },
                                        }}

                                        hideFooter
                                        rows={usersData}
                                        columns={columns}
                                        rowHeight={40}
                                        disableColumnMenu
                                        disableMultipleColumnsSorting
                                        disableColumnReorder
                                        initialState={{ pinnedColumns: { right: ['Edituj', 'Smaž'] } }}

                                    />
                                </StyledBox>
                            </Box>
                        </Box>
                    </Container >
                </ThemeProvider >
            </Box>
            <div>
                <Dialog
                    open={open_edit}
                    onClose={() => { setOpen_edit(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <AccountCircleIcon /> {edit_dialog_header}{"   "}{userData.username}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <MuiGrid container spacing={1}>
                                <MuiGrid item xs={6}>

                                    <TextField
                                        margin="normal"
                                        name="firstName"
                                        label="Přihlašovací jméno"
                                        value={userData.username}
                                        onChange={event => setUserData(
                                            {
                                                ...userData,
                                                username: event.target.value

                                            })}
                                    />
                                </MuiGrid>
                                <MuiGrid item xs={6}>

                                    <TextField
                                        margin="normal"
                                        name="telefon"
                                        label="Tel. cislo"
                                        value={userData.telefon}
                                        onChange={event => setUserData(
                                            {
                                                ...userData,
                                                telefon: event.target.value

                                            })}
                                    />
                                </MuiGrid>

                                <MuiGrid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        name="prefix"
                                        label="Heslo"
                                        type="password"
                                        disabled={userData.domainuser}
                                        value={userData.password}
                                        onChange={event => {
                                            setUserData(
                                                {
                                                    ...userData,
                                                    password: event.target.value

                                                })
                                            password_validation(event.target.value, userData.new_password)
                                        }}

                                    />
                                </MuiGrid>
                                <MuiGrid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        name="lastName"
                                        label="Potvrdzovací heslo"
                                        type="password"
                                        disabled={userData.domainuser}
                                        value={userData.new_password}
                                        onChange={event => {
                                            setUserData(
                                                {
                                                    ...userData,
                                                    new_password: event.target.value

                                                })
                                            password_validation(userData.password, event.target.value)
                                        }}
                                    />
                                    <br />
                                    <span hidden={password_valid} style={{ color: "red" }}><ErrorIcon /> Hesla se neshodují </span>
                                </MuiGrid>
                                <MuiGrid item xs={6}>
                                    <TextField
                                        margin="normal"
                                        name="stredisko"
                                        label="Středisko"
                                        value={userData.stredisko}
                                        onChange={event => {
                                            setUserData(
                                                {
                                                    ...userData,
                                                    stredisko: event.target.value

                                                })
                                            console.log(userData)
                                        }
                                        }
                                    />

                                </MuiGrid>



                                <MuiGrid item xs={6}>

                                    <TextField
                                        margin="normal"
                                        label="Typ"
                                        select
                                        value={userData.typ}
                                        onChange={event => setUserData(
                                            {
                                                ...userData,
                                                typ: event.target.value

                                            })}
                                        name="jedn"

                                    >

                                        <MenuItem value="V"> Vratnice</MenuItem>
                                        <MenuItem value="S"> Sklad</MenuItem>
                                        <MenuItem value="R"> Řidič</MenuItem>
                                        <MenuItem value="I"> Výroba</MenuItem>

                                    </TextField>

                                </MuiGrid>
                                <MuiGrid item xs={6}>
                                    <FormControlLabel control={<Checkbox onChange={(e) => {
                                        if (e.target.checked) {
                                            setUserData({ ...userData, admin: true })
                                        } else {
                                            setUserData({ ...userData, admin: false })
                                        }
                                    }} checked={userData.admin} />} label="Admin" />
                                </MuiGrid>

                                   
                            </MuiGrid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={() => {
                            setOpen_edit(false)
                            
                        }}>
                            <CancelIcon /> {" "} Zrušiť
                        </Button>
                        <Button disabled={!password_valid} onClick={async () => {
                            await axios.post(usersUrl, {
                                id: userData.id,
                                username: userData.username,
                                new_password:  userData.password !== "" ? userData.password : null,
                                telefon: userData.telefon,
                                typ: userData.typ,
                                stredisko: userData.stredisko,
                                admin: userData.admin,
                            },
                                { headers: { Authorization: 'Bearer ' + ldata.Token } })
                                .then(function (response) {
                                    if (userData.id === 0) {
                                        success_save()
                                    } else {
                                        success_edit()
                                    }

                                })
                                .catch(function (error) {
                                    if (error.response.status === 401) {
                                        setError_dialog("Nastala expirace Tokenu, je nutné opětovné přihlášení")
                                    } else if (error.response.status >= 400 && error.response.status < 500) {
                                        setError_dialog(error.response.data)
                                    } else {
                                        setError_dialog("Nastala chyba v komunikaci se serverem , zkuste to později")
                                    }
                                    setOpen(true)

                                })
                            getDataUsers();
                            setOpen_edit(false);
                        
                           
                        }}>
                            <SaveIcon /> {" "} Uložiť
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={open_delete}
                    onClose={() => { setOpen_delete(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Chcete smazat uživatele "} {userData.username} {" ?"}
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={async () => {
                            const headers = {
                                'Authorization': 'Bearer ' + ldata.Token
                            }
                            const data = {
                                id: userData.id
                            }
                            await axios.delete(usersUrl, { headers, data })
                                .then(function (response) {
                                    success_delete();
                                    getDataUsers();
                                })
                                .catch(function (error) {
                                    //errorHandleDialog(error.response)
                                    if (error.response.status === 401) {
                                        setError_dialog("Nastala expirace Tokenu, je nutné opětovné přihlášení")
                                    } else if (error.response.status >= 400 && error.response.status < 500) {
                                        setError_dialog(error.response.data)
                                    } else {
                                        setError_dialog("Nastala chyba v komunikaci se serverem , zkuste to později")
                                    }
                                    setOpen(true)
                                })
                            setOpen_delete(false)
                        }} autoFocus>
                            Ano
                        </Button>
                        <Button onClick={() => { setOpen_delete(false)}} autoFocus>
                            Ne
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={open}
                    onClose={() => { setOpen(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Chyba!"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {error_dialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpen(false) }} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


                <ToastContainer autoClose={2} />
            </div>
            
        </div>

    );
}

export default App;