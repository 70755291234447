import { createSlice } from '@reduxjs/toolkit';

export const loginLoadingSlice = createSlice({
    name: 'loadingLogin',
    initialState: {
        value: true,
    },
    reducers: {
        loadingLogin: (state, action) => {
            state.value = action.payload.data
        },

    },
});

export const { loadingLogin } = loginLoadingSlice.actions;

export const loginLoadingData = state => state.loldata.value;


export default loginLoadingSlice.reducer;
