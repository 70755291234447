import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUrl } from "../../features/urlHelper";
import { login, loginData } from "./redux/loginSlice";
import { loadingLogin, loginLoadingData } from "../Login/redux/loginLoadingSlice";

const theme = createTheme();
const height = window.innerHeight;






function App() {
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ldata = useSelector(loginData);
    const loldata = useSelector(loginLoadingData);


    //console.log(ldata)
    //console.log(loldata)

    const [isDevelop, setIsDevelop] = useState(process.env.NODE_ENV === "production" ? false : true);
    async function submitHandler(x_username, x_password) {
        dispatch(loadingLogin({ data: true }))
        await axios.post(loginUrl, {
            username: x_username,
            password: x_password,
        })
            .then(function (response) {

                dispatch(login({ data: response.data }))
                navigate('/vysyp')
                dispatch(loadingLogin({ data: false }))

            })
            .catch(function (error) {
                console.log(error);
            });

    };


    return (
        <div style={{ minWidth: 300 }}>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            height: height,
                            justifyContent: "center",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h3">
                            VYSYP
                        </Typography>
                        <Typography component="h1" variant="h5">
                            Vyvolávací systém příjmu
                        </Typography>
                        <Box component="form" sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Přihlašovací jméno"
                                onChange={(e) => {

                                    setName(e.target.value)
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Heslo"
                                type="password"
                                //autoComplete="current-password"
                                onChange={(e) => {

                                    setPassword(e.target.value)
                                }}
                                onKeyPress={async (e) => {
                                    dispatch(loadingLogin({ data: true }))
                                    if (e.key === "Enter") {
                                        await axios.post(loginUrl, {
                                            username: name,
                                            password: password,
                                        })
                                            .then(function (response) {

                                                dispatch(login({ data: response.data }))
                                                navigate('/vysyp')
                                                dispatch(loadingLogin({ data: false }))

                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            });

                                    }

                                }

                                }
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={async () => {
                                    dispatch(loadingLogin({ data: true }))
                                    await axios.post(loginUrl, {
                                        username: name,
                                        password: password,
                                    })
                                        .then(function (response) {

                                            dispatch(login({ data: response.data }))
                                            navigate('/vysyp')
                                            dispatch(loadingLogin({ data: false }))

                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        });

                                }

                                }
                            >
                                Přihlášení
                            </Button>


                            <br />
                            <div hidden={!isDevelop}>
                                <Button style={{ margin: "1%" }} variant="contained" onClick={() => { submitHandler("ridic", "ridic") }}>
                                    Ridic
                                </Button>
                                <Button style={{ margin: "1%" }} variant="contained" onClick={() => { submitHandler("sklad", "sklad") }}>
                                    Sklad
                                </Button>
                                <Button style={{ margin: "1%" }} variant="contained" onClick={() => { submitHandler("vratnice", "vratnice") }}>
                                    Vratnice
                                </Button>
                                <Button style={{ margin: "1%" }} variant="contained" onClick={() => { submitHandler("vyroba", "vyroba") }}>
                                    Vyroba
                                </Button>
                                <Button style={{ margin: "1%" }} variant="contained" onClick={() => { submitHandler("admin", "admin") }}>
                                    Admin
                                </Button>
                            </div>

                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    );
}

export default App;