import React, { useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import MuiGrid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { archiveUrl, loginUrl, registraciaUrl, skladUrl, vratnicaArchivUrl, vratnicaUrl, vyvolanieUrl, notifikaciaUrl, opustenieUrl, odbavenieUrl, spzUrl, vyrobaUrl, vyrobaArchivUrl, vyrobaPostUrl } from "../../features/urlHelper";
import { login, loginData } from "../Login/redux/loginSlice";
import { loadingLogin, loginLoadingData } from "../Login/redux/loginLoadingSlice";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { DataGrid, csCZ, GridToolbarProps, GridEditRowsModel, GridColumns, GridCallbackDetails, GridCellParams, GridRowId, GridRowParams, GridToolbar, MuiBaseEvent, MuiEvent, visibleGridColumnsSelector, useGridApiRef, gridVisibleSortedRowIdsSelector, gridVisibleRowCountSelector, visibleGridColumnsLengthSelector, GridToolbarFilterButton, GridToolbarDensitySelector, GridRowModelUpdate, GridRowsProp, GridEditCellValueParams, GridValueFormatterParams, GridEditCellPropsParams, GridCellEditCommitParams, GridActionsCellItem, GridEventListener, GridEvents, GridToolbarContainer, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { getLicense } from './../../features/Licence';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import notifikacia_mp3 from './sounds/stories_sounds_boop.mp3';
import CreateIcon from '@mui/icons-material/Create';
import { Divider, Menu } from '@mui/material';
import Fade from '@mui/material/Fade';

const StyledBox = styled(Box)(({ theme }) => ({
    height: 500,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    '& .cell-status-1': {
        backgroundColor: '#ffcccb',
    },
    '& .cell-status-2': {
        backgroundColor: 'yellow',
    },
    '& .cell-status-3': {
        backgroundColor: '#90EE90',
    },
    '& .row-status-3S': {
        backgroundColor: '#FAFAFA',
        color: '#ABB0B8'
    },
    '& .row-status-2V': {
        backgroundColor: '#FAFAFA',
        color: '#ABB0B8'
    },

}));

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
    '& .form-control': {
        width: 250,
    },
    '& .country-list': {
        width: 250,
    },
}
));
const theme = createTheme();
//const height = window.innerHeight;
const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);




function App() {
    LicenseInfo.setLicenseKey(getLicense());
    const [open_edit, setOpen_edit] = React.useState(false);
    const [open_err, setOpen_err] = React.useState(false);
    const [open_ok, setOpen_ok] = React.useState(false);
    const [open_ok_zmena, setOpen_ok_zmena] = React.useState(false);
    const [open_podmienky, setOpen_podmienky] = React.useState(false);
    const [open_ramp, setOpen_ramp] = React.useState(false);
    const [err_msg, setErr_msg] = React.useState(false);
    const [open_medzi, setOpen_medzi] = React.useState(false);
    const [open_opustenie, setOpen_opustenie] = React.useState(false);
    const [open_odbaveni, setOpen_odbaveni] = React.useState(false);
    const [open_vyr, setOpen_vyr] = React.useState(false);


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ldata = useSelector(loginData);
    const loldata = useSelector(loginLoadingData);

    const [notificationID, setNotificationID] = useState("");
    const [connection, setConnection] = useState(null);
    const [data_s, setData_s] = React.useState([]);
    const [data_v, setData_v] = React.useState([]);
    const [data_vyr, setData_vyr] = React.useState([]);
    const [statistika, setStatistika] = React.useState({});
    const [rows_loaded_s, setRowsLoaded_s] = useState(false)
    const [rows_loaded_v, setRowsLoaded_v] = useState(false)
    const [rows_loaded_vyr, setRowsLoaded_vyr] = useState(false)
    const [arch, setArch] = useState(false)
    const [rampy, setRampy] = React.useState([]);
    const [rampy_active, setRampy_active] = React.useState([]);




    const [currency, setCurrency] = React.useState('EPAL');
    const [id, setId] = React.useState(0);
    const [rz, setRz] = React.useState('');
    const [dodavatel, setDodavatel] = React.useState('');
    const [ridic, setRidic] = React.useState('');
    const [tel, setTel] = React.useState('+420');
    const [pocet, setPocet] = React.useState(0);
    const [vaha, setVaha] = React.useState(0);
    const [v_n, setV_n] = React.useState("V");
    const [tepl, setTepl] = React.useState("1");
    const [poznamka_ridic, setPoznamkaRidic] = React.useState('');
    const [checked, setChecked] = React.useState(false);
    const [pozn_odbav, setPozn_odb] = React.useState("");

    const [vaha_out, setVaha_out] = React.useState(0);
    const [paleta_out, setPaletaOut] = React.useState('');
    const [poznamka_sklad, setPoznamkaSklad] = React.useState("");

    const [id_car, setId_car] = React.useState('');
    const [id_ramp, setId_ramp] = React.useState(1);
    const [actual_row, setActual_row] = React.useState([]);
    const audio = new Audio(notifikacia_mp3);

    const [palety_odbaveni, setPaletyOdbaveni] = useState([
        { pocet: 0, jedn: 'EPAL' }
    ])

    const handleFormChange = (index, event) => {
        let data = [...palety_odbaveni];
        if (event.target.name === "pocet")
            data[index][event.target.name] = parseInt(event.target.value);
        else
            data[index][event.target.name] = event.target.value;
        setPaletyOdbaveni(data);
    }

    const addFields = () => {
        let newfield = { pocet: 0, jedn: 'EPAL' }
        setPaletyOdbaveni([...palety_odbaveni, newfield])
    }

    const removeFields = (index) => {
        let data = [...palety_odbaveni];
        data.splice(index, 1)
        setPaletyOdbaveni(data)
    }


    const [anchorEl_admin, setAnchorEl_admin] = useState(null);
    const open_admin = Boolean(anchorEl_admin);

    const handleClose_admin = () => {
        setAnchorEl_admin(null);
    };

    const handleClick_admin = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl_admin(event.currentTarget);
    };

    const currencies = [
        {
            value: 'EPAL',
            label: 'EPAL',
        },
        {
            value: 'KRT',
            label: 'KRT',
        },
        {
            value: 'IBC',
            label: 'IBC',
        }
    ];

    const podmienkyRegistracie =
        "1.\n" +
        "Zákaz kouření v celém areálu - pokuta 5.000 Kč\n" +
        "Smoking is strictly prohibited!\n" +
        "\n" +
        "2.\n" +
        "Maximální rychlost v areálu je 15 km / h\n" +
        "Max speed 15 km / h\n" +
        "\n" +
        "3.\n" +
        "Zákaz pohybu po areálu bez doprovodu zaměstnance Crocodille\n" +
        "Do not leave vehicle while waiting in the area.\n";

    useEffect(() => {
        let expiration_time = new Date(ldata.TokenExpirationTime * 1000)
        expiration_time = new Date(expiration_time.toUTCString())
        const current_time = new Date(new Date().toUTCString())

        console.log("expiracia", expiration_time)
        console.log("teraz", current_time)
        console.log("ldata", ldata)

        if (current_time > expiration_time) {
            console.log("good way")
            navigate('/')
        }
    }, []);


    useEffect(() => {
        if (ldata.UserData.typ === "V") {
            getDataVratnice()
        }
        if (ldata.UserData.typ === "S") {
            getDataSklad()
        }
        if (ldata.UserData.typ === "I") {
            getDataVyroba()
        }
    }, []);




    useEffect(() => {
        //console.log("hub notification build", notifikaciaUrl + '?username=' + ldata.UserData.username)
        //.withUrl(notifikaciaUrl + '?username=' + ldata.UserData.username)
        const connect = new HubConnectionBuilder()
            .withUrl(notifikaciaUrl + '?username=' + ldata.UserData.username)
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    useEffect(() => {
        if (connection) {
            console.log("hub notification start")
            connection
                .start()
                .then(() => {
                    //ziskame notificationID ( kvoli update)
                    connection.invoke('GetConnectionId')
                        .then(function (connectionID) {
                            setNotificationID(connectionID);
                            console.log("connectionID", connectionID)
                        });

                    connection.on("ReceiveMessage", (message) => {
                        console.log("ReceiveMessage", message)
                        console.log("typ", ldata.UserData.typ, "username", message.username)

                        if (ldata.UserData.typ === "V") {
                            getDataVratnice()
                        }
                        if (ldata.UserData.typ === "S") {
                            getDataSklad()
                            if (message.typ === "REGISTRACIA") {
                                audio.play()
                            }
                        }
                        if (ldata.UserData.typ === "I" && message.typ === "VYVOLANIE" && ldata.UserData.username === message.username) {
                            // TODO Matej
                            getDataVyroba()

                            // este ding dong
                            audio.play()
                        }
                    });
                })
                .catch((error) => console.log(error));
        }
    }, [connection]);


    const [muiTableKey, setMuiTableKey] = React.useState(0);

    async function getDataSklad() {
        await axios.get(skladUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                // vymazanie filtra
                setMuiTableKey(muiTableKey + 1);

                setColumnVisibilityModel({ vyvolani: true, odbaveni: true, opusteni: false, stravenyCas: false, cas_privolania: false, cas_odbavenia: false, obdavenieCas: false })
                console.log("response s", response)
                setData_s(response.data.auta)
                setStatistika({
                    priemer_dodavatel: response.data.priemer_dodavatel,
                    priemer_vykladka: response.data.priemer_vykladka
                })
                setRampy(response.data.rampy_all)
                setRampy_active(response.data.rampy_active)
                setRowsLoaded_s(true)
                console.log("columns", columns)
                setArch(false)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    async function getSpz(spz) {
        await axios.get(spzUrl + "/" + spz,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {

                if (response.data.telefon.length === 0) {
                    setTel("+420")
                }
                else {
                    setTel(response.data.telefon[0].slice(1))
                }
                if (response.data.ridic.length === 0) {
                    setRidic("")
                }
                else {
                    setRidic(response.data.ridic[0])
                }
                if (response.data.dodavatel.length === 0) {
                    setDodavatel("")
                }
                else {
                    setDodavatel(response.data.dodavatel[0])
                }
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    async function getDataSkladA() {
        await axios.get(archiveUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                // vymazanie filtra
                setMuiTableKey(muiTableKey + 1);

                setColumnVisibilityModel({ vyvolani: false, odbaveni: false, opusteni: false, stravenyCas: true, cas_privolania: true, cas_odbavenia: true, obdavenieCas: true })
                console.log("response sa", response.data)
                setData_s(response.data)
                setStatistika({})
                setArch(true)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    async function getDataVratnice() {
        await axios.get(vratnicaUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                setColumnVisibilityModel({ vyvolani: false, odbaveni: false, opusteni: true, stravenyCas: false, cas_privolania: false, cas_odbavenia: false, obdavenieCas: false })
                console.log("response V", response)
                setData_v(response.data)

                setRowsLoaded_v(true)

                setArch(false)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    async function getDataVratniceA() {
        await axios.get(vratnicaArchivUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                setColumnVisibilityModel({ vyvolani: false, odbaveni: false, opusteni: false, stravenyCas: true, cas_privolania: true, cas_odbavenia: true, obdavenieCas: true })
                console.log("response va", response.data)
                setData_v(response.data)
                setArch(true)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    async function getDataVyroba() {
        await axios.get(vyrobaUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                setColumnVisibilityModel({ vyvolani: false, odbaveni: false, opusteni: false, stravenyCas: false, cas_privolania: false, cas_odbavenia: false, obdavenieCas: false })
                console.log("response Vyr", response)
                setData_vyr(response.data)

                setRowsLoaded_vyr(true)

                setArch(false)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }


    async function getDataVyrobaA() {
        await axios.get(vyrobaArchivUrl,
            { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                setColumnVisibilityModel({ vyvolani: false, odbaveni: false, opusteni: false, stravenyCas: false, cas_privolania: false, cas_odbavenia: false, obdavenieCas: false })
                console.log("response VyrA", response)
                setData_vyr(response.data)

                setRowsLoaded_vyr(true)

                setArch(true)
            })
            .catch(function (error) {
                console.log("error response", error)

            })
    }

    function open_edit_win(row) {
        setOpen_edit(true)
        setId(row.id)
        setCurrency(row.in_palety_jedn);
        setRz(row.spz);
        setDodavatel(row.dodavatel);
        setRidic(row.ridic);
        setTel(row.telefon);
        setPocet(row.in_paleta_pocet);
        setVaha(row.in_vaha);
        setV_n(row.typ);
        setTepl(row.tepl_rezim_value);
        setPoznamkaRidic(row.poznamka_ridic);
        setChecked(true);

    }

    function open_reg_win(row) {
        setOpen_vyr(true)
        setId("")
        setCurrency('EPAL');
        setTel(ldata.UserData.telefon === "" ? "+420" : ldata.UserData.telefon);
        setPocet(0);
        setTepl("1");
        setPoznamkaRidic("");

    }

    function open_reg_edit_win(row) {
        setOpen_vyr(true)
        setId(row.id)
        setCurrency(row.in_palety_jedn);
        setTel(row.telefon);
        setPocet(row.in_paleta_pocet);
        setTepl(row.tepl_rezim_value);
        setPoznamkaRidic(row.poznamka_ridic);

    }


    //function open_edit_clear() {
    //    setOpen_edit(true)
    //    setCurrency('EPAL');
    //    setRz('');
    //    setDodavatel('');
    //    setRidic('');
    //    setTel('+420');
    //    setPocet(0);
    //    setVaha(0);
    //    setV_n("V");
    //    setTepl("1");
    //    setPoznamkaRidic('');
    //    setChecked(false);
    //};

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    function handleClose_edit() {
        setOpen_edit(false);
        setCurrency('EPAL');
        setRz('');
        setDodavatel('');
        setRidic('');
        setTel('+420');
        setPocet(0);
        setVaha(0);
        setV_n("V");
        setTepl("1");
        setPoznamkaRidic('');
        setId(0);
        setChecked(false);
    };

    function handleClose_err() {
        setOpen_err(false);

    };

    async function handleClose_ramp() {
        setOpen_ramp(false);
        await axios.post(vyvolanieUrl, {
            id_auto: id_car,
            id_rampa: id_ramp,
            notificationID: notificationID
        }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                console.log("response close ramp", response)
            })
            .catch(function (error) {
                setOpen_err(true)
                setErr_msg(error.response.data)
                console.log(error)
            });
        setOpen_medzi(false)
        getDataSklad()
    };

    async function handleClose_opustenie() {
        await axios.post(opustenieUrl, {
            id_auto: id_car,
            out_vaha: parseInt(vaha_out),
            notificationID: notificationID
        }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                console.log("response opustenie", response)

            })
            .catch(function (error) {
                setOpen_err(true)
                setErr_msg(error.response.data)
                console.log(error)
            });
        setVaha(0)
        setVaha_out(0)
        setPoznamkaSklad('')
        setPaletaOut('')
        setOpen_opustenie(false)
        getDataVratnice()
    };

    async function handleClose_odbaveni() {
        await axios.post(odbavenieUrl, {
            id_auto: id_car,
            palety: palety_odbaveni,
            poznamka: pozn_odbav,
            notificationID: notificationID
        }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
            .then(function (response) {
                console.log("response odbaveni", response)

            })
            .catch(function (error) {
                setOpen_err(true)
                setErr_msg(error.response.data)
                console.log(error)
            });
        setOpen_odbaveni(false)
        getDataSklad()
    };

    function handleClose_ok() {
        setOpen_edit(false);
        setOpen_vyr(false);
        setOpen_ok(false)
        setCurrency('EPAL');
        setRz('');
        setDodavatel('');
        setRidic('');
        setTel('+420');
        setPocet(0);
        setVaha(0);
        setV_n("V");
        setTepl("1");
        setPoznamkaRidic('');
        setId(0)
        setChecked(false);
    };

    function handleClose_ok_zmena() {
        setOpen_edit(false);
        setOpen_vyr(false);
        setOpen_ok_zmena(false)
        setCurrency('EPAL');
        setRz('');
        setDodavatel('');
        setRidic('');
        setTel('+420');
        setPocet(0);
        setVaha(0);
        setV_n("V");
        setTepl("1");
        setPoznamkaRidic('');
        setId(0)
        setChecked(false);
    };

    const columns = [
        {
            field: 'spz',
            headerName: ldata.UserData.typ === "I" ? "" : 'RZ',
            width: 120,
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => (
                <>
                    <div title={"Nastavit"}>
                        <span >{params.row.spz}</span>
                        <span hidden={ldata.UserData.typ !== "V" || params.row.status !== 1} onClick={() => { open_edit_win(params.row) }} style={{ margin: 10, cursor: "pointer" }}>< CreateIcon fontSize="25" /></span>
                        <span hidden={ldata.UserData.typ !== "I" || params.row.status !== 1} onClick={() => { open_reg_edit_win(params.row) }} style={{ margin: 10, cursor: "pointer" }}>< CreateIcon fontSize="25" /></span>
                    </div>

                </>
            ),

        },
        {
            field: 'dodavatel_ridic',
            headerName: 'Dodavatel/řidič',
            width: 150,
            headerAlign: "center",
            sortable: false,
            hidden: false,
            renderCell: (params) => (
                <>
                    <div title={params.row.userType === "I" ? params.row.x__adduser : params.row.dodavatel + '\n' +
                        params.row.userType === "I" ? params.row.userStredisko : params.row.ridic + '\n' +
                    params.row.telefon}>
                        <span>{params.row.userType === "I" ? params.row.x__adduser : params.row.dodavatel}</span><br />
                        <span style={{ fontStyle: 'italic' }} >{params.row.userType === "I" ? params.row.userStredisko : params.row.ridic}</span><br />
                        <span>{params.row.telefon}</span>
                    </div>

                </>
            ),
        },
        {
            field: 'typ_text',
            headerName: ldata.UserData.typ === "I" ? "Druh zboží" : "Vykl./nakl.",
            width: 120,
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => (
                <>
                    <div >
                        <span>{params.row.typ_text}</span><br />
                        <span style={{ fontStyle: 'italic' }}>{params.row.tepl_rezim}</span>
                    </div>

                </>
            ),


        },
        {
            field: 'in_paleta',
            headerName: 'Palety',
            width: 120,
            align: 'center',
            headerAlign: "center",
            sortable: false,
            renderCell: (params) => (
                <>
                    <div title={'Palety při registraci: ' + params.row.in_paleta + '\nPalety při opuštění vrátnice:' + params.row.out_paleta}>
                        <span>{params.row.in_paleta === null ? '' : '-> ' + params.row.in_paleta}</span><br />
                        <span style={{ color: 'green' }}>{params.row.out_paleta === null ? '' : params.row.out_paleta + ' ->'}</span>
                    </div>

                </>
            ),


        },
        {
            field: 'cas_prijazdu',
            headerName: ldata.UserData.typ === "I" ? "Čas registrace" : "Čas příjezdu",
            width: ldata.UserData.typ === "I" ? 120 : 100,
            align: 'center',
            headerAlign: "center",
            sortable: false


        },
        {
            field: 'status_cas',
            headerName: 'Status',
            headerAlign: "center",
            width: 120,
            sortable: false,
            cellClassName: (params) => `cell-status-${params.row.status}`,
            renderCell: (params) => (
                <>
                    <div title={params.row.status_cas + '\n' + params.row.status_poznamka}>
                        <span>{params.row.status_text}</span>  <span style={{ color: "red" }}>{params.row.delay ? "!" : ""}</span> <br />
                        <span>{params.row.status_text2}</span><br />
                        <span style={{ fontStyle: 'italic' }}>{params.row.status_poznamka}</span>
                    </div>

                </>
            ),


        },
        {
            field: 'stravenyCas',
            headerName: 'Stravený čas',
            headerAlign: "center",
            align: 'right',
            width: 120,
            sortable: false,
            cellClassName: (params) => `cell-status-${params.row.status}`,
        },
        {
            field: 'cas_privolania',
            headerName: 'Čas vyvolani',
            headerAlign: "center",
            align: 'right',
            width: 120,
            sortable: false,
        },
        {
            field: 'cas_odbavenia',
            headerName: 'Čas odbavení',
            headerAlign: "center",
            align: 'right',
            width: 120,
            sortable: false,
        },
        {
            field: 'obdavenieCas',
            headerName: 'Odbaven za',
            headerAlign: "center",
            align: 'right',
            width: 120,
            sortable: false,
        },

        {
            field: 'vyvolani',
            headerName: ' ',
            headerAlign: "center",
            sortable: false,
            width: 120,
            pinnable: true,
            renderCell: (params) => (
                <>
                    <Button variant="outlined" disabled={params.row.status > 2} style={{ width: 110 }} onClick={() => {

                        setOpen_ramp(true)
                        setId_car(params.row.id)

                        const id = rampy.find(rampa => rampa.nazov === params.row.rampa);
                        if (id)
                            setId_ramp(id.id)
                        else
                            setId_ramp(rampy[0].id)
                    }}>
                        <div>
                            <span>Vyvolání</span><br />
                            <span>řidiče</span>
                        </div>

                    </Button>

                </>
            ),
        },
        {
            field: 'odbaveni',
            headerName: ' ',
            headerAlign: "center",
            width: 120,
            sortable: false,
            pinnable: true,
            hidden: false,
            renderCell: (params) => (

                <>
                    <Button variant="outlined" disabled={params.row.status !== 2} style={{ width: 110 }} color="error" onClick={() => {
                        console.log(params.row)
                        setId_car(params.row.id)
                        setActual_row(params.row)
                        setOpen_odbaveni(true)
                    }}>
                        Odbavení
                    </Button>

                </>
            ),
        },

        {
            field: 'opusteni',
            headerName: ' ',
            headerAlign: "center",
            width: 250,
            sortable: false,
            pinnable: true,
            hidden: false,
            renderCell: (params) => (

                <>
                    <Button variant="outlined" disabled={false/*params.row.status !== 3 && (params.row.typ === 'S' && params.row.status === 1) === false*/} style={{ width: 230 }} color="error" onClick={() => {
                        console.log(params.row)
                        setOpen_opustenie(true)
                        setId_car(params.row.id)
                        setVaha_out(params.row.out_vaha)
                        setVaha(params.row.in_vaha)
                        if (params.row.status === 1)
                            setPaletaOut(params.row.in_paleta === null ? '-' : params.row.in_paleta)
                        else
                            setPaletaOut(params.row.out_paleta === null ? '-' : params.row.out_paleta)
                        setPoznamkaSklad(params.row.poznamka_sklad)
                    }}>
                        {params.row.status === 1 ? "Opuštění bez odbavení" : "Opuštění vratnice"}
                    </Button>

                </>
            ),
        },

    ];

    const [columnVisibilityModel, setColumnVisibilityModel] =
        React.useState({
            vyvolani: true,
            odbaveni: true,
        });

    const [queryOptions, setQueryOptions] = React.useState({});

    const onFilterChange = React.useCallback((filterModel) => {
        // ulozenie podmienok pre filter
        setQueryOptions({ filterModel: { ...filterModel } });
    }, []);


    return (
        <div style={{ minWidth: 480 }} >
            <Box sx={{ flexGrow: 1, overflowX: "scroll", }}>
                <AppBar >
                    <Toolbar>
                        {

                        }
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {loldata ? "" : ldata.UserData.userinfo}
                        </Typography>
                        {ldata.UserData.admin
                            ? <>   <Button
                                sx={{ color: "white", textTransform: "none", fontWeight: 'bold' }}
                                id="fade-button"
                                aria-controls={open_admin ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open_admin ? 'true' : undefined}
                                onClick={handleClick_admin}
                            >
                                Admin
                            </Button>
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl_admin}
                                    open={open_admin}
                                    onClose={handleClose_admin}
                                    TransitionComponent={Fade}
                                >
                                    <MenuItem onClick={() => {
                                        connection.off("ReceiveMessage");
                                        navigate('/admin');
                                    }} > Seznam uživatelů</MenuItem>
                                  
                                </Menu></>
                            : <></>
                        }


                        <Button color="inherit" onClick={() => {
                            connection.off("ReceiveMessage");
                            navigate('/')
                        }}>Odhlášení</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box>
                <ThemeProvider theme={theme}>
                    <Container component="main" maxWidth="xs">
                        <CssBaseline />
                        <Box
                            sx={{
                                height: height,
                                width: "100%",
                                display: 'flex',
                                justifyContent: "center",
                                alignItems: 'center',
                            }}
                        >

                            <Typography hidden={ldata.UserData.typ === "V" || ldata.UserData.typ === "S" || ldata.UserData.typ === "I"} component="h1" variant="h3">
                                <Button size="large" variant="contained" onClick={() => { setOpen_edit(true) }}>Registrace řidiče / Registration </Button>
                            </Typography>


                            <div hidden={ldata.UserData.typ === "V" || ldata.UserData.typ === "R"} >

                                {
                                    rows_loaded_s
                                        ? <Box sx={{ height: height - 200, width: width - 50 }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div>
                                                    <Button onClick={() => {
                                                        if (arch) {
                                                            getDataSklad()
                                                        } else {
                                                            getDataSkladA()
                                                        }

                                                    }} size="large" variant="contained" >{!arch ? "Archiv" : "Aktuální stav"}</Button>
                                                </div>
                                                <div style={!arch ? { backgroundColor: "lightgray", borderRadius: 2, padding: 5, margin: 5, marginLeft: 55, display: "flex", justifyContent: "center", textAlign: "center", fontSize: 14 } : {}}>{!arch ? "Průměrna  vykládky jedné palety: " : ""} <b> {!arch ? statistika.priemer_vykladka : ""}</b></div>

                                                <div style={!arch ? { backgroundColor: "lightgray", borderRadius: 2, padding: 5, margin: 5, display: "flex", justifyContent: "center", textAlign: "center", fontSize: 14 } : {}}>{!arch ? "Průměrna čekací doba dodavatele: " : ""} <b>{!arch ? statistika.priemer_dodavatel : ""}</b></div>

                                            </div>
                                            <StyledBox>
                                                <DataGridPro
                                                    key={muiTableKey}
                                                    columns={columns}
                                                    columnVisibilityModel={columnVisibilityModel}
                                                    onColumnVisibilityModelChange={(newModel) =>
                                                        setColumnVisibilityModel(newModel)
                                                    }
                                                    rows={data_s}
                                                    hideFooter={!arch}
                                                    /* disableColumnMenu={!arch} */
                                                    rowHeight={70}
                                                    //getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                                    getRowClassName={(params) => `row-status-${params.row.status}${ldata.UserData.typ}`}
                                                    onFilterModelChange={onFilterChange}

                                                />
                                            </StyledBox>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    '& > :not(style)':
                                                    {
                                                        mt: 2,
                                                        width: 200,
                                                        height: 150,
                                                    },
                                                }}
                                            >{
                                                    rampy_active.map((rampa, index) => (<Paper key={index} style={{ padding: 10 }} elevation={3}><span style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>{rampa.nazov + "\r\n"}</span>
                                                        {rampa.spz.map((s, index) => (<span key={index} style={{ whiteSpace: 'pre-wrap' }}>{s + "\r\n"}</span>))
                                                        }
                                                    </Paper>))
                                                }



                                            </Box>
                                        </Box>
                                        : <div></div>
                                }


                            </div>
                            <Typography hidden={ldata.UserData.typ === "R" || ldata.UserData.typ === "S" } component="h1" variant="h3">
                                {
                                    rows_loaded_v
                                        ? <Box sx={{ height: height-200, width: width - 50 }}>
                                            <Box
                                                sx={{
                                                    height: 50,
                                                    width: "100%",
                                                    display: 'flex',
                                                    justifyContent: "center",
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button size="large" variant="contained" onClick={() => { setOpen_edit(true) }}>Registrace řidiče</Button>
                                            </Box>
                                            <Button onClick={() => {
                                                if (arch) {
                                                    getDataVratnice()
                                                } else {
                                                    getDataVratniceA()
                                                }

                                            }} size="large" variant="contained" >{!arch ? "Archiv" : "Aktuální stav"}</Button>
                                            <StyledBox>
                                                <DataGridPro
                                                    columns={columns}
                                                    columnVisibilityModel={columnVisibilityModel}
                                                    onColumnVisibilityModelChange={(newModel) =>
                                                        setColumnVisibilityModel(newModel)
                                                    }
                                                    rows={data_v}
                                                    hideFooter
                                                    disableColumnMenu
                                                    rowHeight={70}
                                                    //getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                                    getRowClassName={(params) => `row-status-${params.row.status}${ldata.UserData.typ}`}


                                                />
                                            </StyledBox>
                                        </Box>
                                        : <div></div>
                                }

                            </Typography>
                            <Typography hidden={ldata.UserData.typ === "R" || ldata.UserData.typ === "S" || ldata.UserData.typ === "V"} component="h1" variant="h3">
                                {
                                    rows_loaded_vyr
                                        ? <Box sx={{ height: height-200, width: width - 50 }}>
                                            <Box
                                                sx={{
                                                    height: 50,
                                                    width: "100%",
                                                    display: 'flex',
                                                    justifyContent: "center",
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button size="large" variant="contained" onClick={() => { open_reg_win() }}>Požadavek pro sklad</Button>
                                            </Box>
                                            <Button onClick={() => {
                                                if (arch) {
                                                    getDataVyroba()
                                                } else {
                                                    getDataVyrobaA()
                                                }

                                            }} size="large" variant="contained" >{!arch ? "Archiv" : "Aktuální stav"}</Button>
                                            <StyledBox>
                                                <DataGridPro
                                                    columns={columns}
                                                    columnVisibilityModel={columnVisibilityModel}
                                                    onColumnVisibilityModelChange={(newModel) =>
                                                        setColumnVisibilityModel(newModel)
                                                    }
                                                    rows={data_vyr}
                                                    hideFooter
                                                    disableColumnMenu
                                                    rowHeight={70}
                                                    //getRowClassName={(params) => `super-app-theme--${params.row.status}`}
                                                    getRowClassName={(params) => `row-status-${params.row.status}${ldata.UserData.typ}`}


                                                />
                                            </StyledBox>
                                        </Box>
                                        : <div></div>
                                }

                            </Typography>




                        </Box>

                    </Container>
                </ThemeProvider>
            </Box>
            <Dialog
                open={open_edit}
                onClose={handleClose_edit}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Registrace řidiče / Registration:"}
                </DialogTitle>
                <DialogContent>

                    <MuiGrid container spacing={0.15}>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>RZ /Truck registration no.</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <TextField
                                margin="normal"
                                value={rz}
                                label="RZ / Truck registration no."
                                size="small"
                                name="rz"
                                autoComplete="on"
                                style={{ width: 100 }}
                                maxLength={10}
                                onChange={(e) => {
                                    setRz(e.target.value.toUpperCase())
                                    if (e.target.value.length > 5) {
                                        getSpz(e.target.value)
                                    }
                                }}


                            />
                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Dodavatel / Supplier/company</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <TextField
                                margin="normal"
                                value={dodavatel}
                                label="Dodavatel / Supplier/company"
                                size="small"
                                name="dodavatel"
                                autoComplete="on"
                                style={{ width: 300 }}
                                maxLength={50}
                                onChange={(e) => { setDodavatel(e.target.value) }}
                            />
                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Příjmení řidiče / Driver name</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <TextField
                                margin="normal"
                                value={ridic}
                                label="Příjmení řidiče / Driver name"
                                size="small"
                                name="ridic"
                                style={{ width: 300 }}
                                autoComplete="on"
                                maxLength={50}
                                onChange={(e) => { setRidic(e.target.value) }}


                            />
                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 0, fontWeight: 'bold' }}>Telefon / Telephone</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>
                            <PhoneInput
                                value={tel}
                                onChange={phone => setTel(phone)}
                                country={"cz"}
                                regions="europe"
                                preferredCountries={["cz", "sk", "pl", "de", "at", "hu"]}
                            />

                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 0, fontWeight: 'bold' }}>Vykládka/nakládka /<br /> Unloading/loading</Box>
                        </MuiGrid>
                        <MuiGrid item xs={6}>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={v_n}
                                onChange={(e) => { setV_n(e.target.value) }}
                            >
                                <FormControlLabel value="V" control={<Radio />} label="Vykládka / Unload" />
                                <FormControlLabel value="N" control={<Radio />} label="Nakládka / Load" />
                                <FormControlLabel value="S" control={<Radio />} label="Servis / Servis" />

                            </RadioGroup>
                        </MuiGrid>

                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 0, fontWeight: 'bold' }}>Počet palet/krt / No. Of pallets</Box>
                        </MuiGrid>
                        <MuiGrid item xs={3}>

                            <TextField
                                margin="normal"
                                value={pocet}
                                label="Počet palet / No. Of pallets"
                                size="small"
                                name="poradie"
                                type="number"
                                onChange={(e) => { setPocet(e.target.value) }}
                                onFocus={(e) => { e.target.select() }}

                            />

                        </MuiGrid>
                        <MuiGrid item xs={2}>

                            <TextField
                                margin="normal"
                                label="Typ/Type"
                                select
                                value={currency}
                                onChange={(e) => { setCurrency(e.target.value) }}
                                size="small"
                                name="poradie"

                            //onChange={handleChange}


                            >
                                <MenuItem key={currencies[0].value} value={currencies[0].value}>
                                    {currencies[0].label}
                                </MenuItem >
                                <MenuItem key={currencies[1].value} value={currencies[1].value}>
                                    {currencies[1].label}
                                </MenuItem >
                                <MenuItem key={currencies[2].value} selected value={currencies[2].value}>
                                    {currencies[2].label}
                                </MenuItem >

                            </TextField>

                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Vaha / Weight</Box>
                        </MuiGrid>
                        <MuiGrid item xs={3}>

                            <TextField
                                margin="normal"
                                value={vaha}
                                label="Vaha/Weight"
                                size="small"
                                name="vaha"
                                type="number"
                                onChange={(e) => { setVaha(e.target.value) }}
                                onFocus={(e) => { e.target.select() }}
                            />

                        </MuiGrid>
                        <MuiGrid item xs={2}>
                            <Box sx={{ color: 'text.secondary', mt: 3 }}> kg</Box>
                        </MuiGrid>

                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Teplotní režim /<br /> Temperature mode</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={tepl}
                                onChange={(e) => { setTepl(e.target.value) }}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Chlazené do 8°C / Refrigerated" />
                                <FormControlLabel value="2" control={<Radio />} label="Mrazené -18°C / Frozen" />
                                <FormControlLabel value="3" control={<Radio />} label="Suche / Dry" />
                                <FormControlLabel value="4" control={<Radio />} label="Kombinace / Combination" />


                            </RadioGroup>
                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Poznámka / Note</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <TextField
                                margin="normal"
                                value={poznamka_ridic}
                                label="Poznámka / Note"
                                size="small"
                                style={{ width: 300 }}
                                name="poznamka_ridic"
                                maxLength={100}
                                onChange={(e) => { setPoznamkaRidic(e.target.value) }}


                            />
                        </MuiGrid>
                        <MuiGrid item xs={1}>
                            <div hidden={ldata.UserData.typ === "V"}>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    sx={{ '& .MuiSvgIcon-root': { margin: "60%" } }}
                                />
                            </div>
                        </MuiGrid>
                        <MuiGrid item xs={11} >
                            <Box hidden={ldata.UserData.typ === "V"} onClick={() => { setOpen_podmienky(true) }} sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold', cursor: 'pointer' }}>Souhlasím s podmínkami pro vjezd do areálu /<br /> I Agree with conditions for entry</Box>
                        </MuiGrid>


                    </MuiGrid>




                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 5 }}>
                    {
                        id > 0
                            ? <Button
                                disabled={!checked && ldata.UserData.typ === "R"}
                                variant="contained"
                                onClick={async () => {
                                    console.log(v_n.length)
                                    await axios.put(registraciaUrl + "/" + id, {
                                        spz: rz,
                                        dodavatel: dodavatel,
                                        ridic: ridic,
                                        telefon: tel,
                                        typ: v_n,
                                        in_vaha: parseInt(vaha),
                                        in_paleta_pocet: parseInt(pocet),
                                        in_palety_jedn: currency,
                                        tepl_rezim: tepl,
                                        poznamka_ridic: poznamka_ridic,
                                        userType: ldata.userType
                                    }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
                                        .then(function (response) {
                                            setOpen_ok_zmena(true)
                                            console.log(response)
                                            getDataVratnice()

                                        })
                                        .catch(function (error) {
                                            setOpen_err(true)
                                            setErr_msg(error.response.data)

                                        });

                                }

                                }

                            >
                                Opravit
                            </Button>
                            : <Button
                                disabled={!checked && ldata.UserData.typ === "R"}
                                variant="contained"
                                onClick={async () => {
                                    console.log(v_n.length)
                                    console.log(tepl)
                                    await axios.post(registraciaUrl, {
                                        spz: rz,
                                        dodavatel: dodavatel,
                                        ridic: ridic,
                                        telefon: tel,
                                        typ: v_n,
                                        in_vaha: parseInt(vaha),
                                        in_paleta_pocet: parseInt(pocet),
                                        in_palety_jedn: currency,
                                        tepl_rezim: tepl,
                                        poznamka_ridic: poznamka_ridic,
                                        userType: ldata.userType
                                    }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
                                        .then(function (response) {
                                            setOpen_ok(true)
                                            console.log(response)
                                            getDataVratnice()

                                        })
                                        .catch(function (error) {
                                            setOpen_err(true)
                                            setErr_msg(error.response.data)

                                        });

                                }

                                }

                            >
                                Registrovat / Register
                            </Button>
                    }


                </DialogActions>
            </Dialog>
            <Dialog
                open={open_err}
                onClose={handleClose_err}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span style={{ whiteSpace: 'pre-wrap' }}>{err_msg}</span>
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose_err} >
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={open_ok}
                onClose={() => { setOpen_ok(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>{"RZ byla zaregistrována / Successfully registered"}</span>
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose_ok} >
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={open_ok_zmena}
                onClose={() => { setOpen_ok_zmena(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>{"Úspěšně změněno / Successfully changed"}</span>
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose_ok_zmena} >
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>
            <Dialog
                open={open_podmienky}
                onClose={() => { setOpen_podmienky(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span> Podmínky pro vjezd do areálu / Conditions for entry:</span>
                </DialogTitle>
                <DialogContent>
                    <span style={{ whiteSpace: 'pre-wrap' }}>{podmienkyRegistracie}</span>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => { setOpen_podmienky(false) }} >
                        Ok
                    </Button>

                </DialogActions>
            </Dialog>


            <Dialog
                open={open_ramp}
                onClose={() => { setOpen_ramp(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>{"Vyvolani řidiče na rampu"}</span>
                </DialogTitle>
                <DialogContent>

                    <MuiGrid container spacing={4}>
                        <MuiGrid item xs={3} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Rampa</Box>
                        </MuiGrid>
                        <MuiGrid item xs={9}>
                            <TextField
                                margin="normal"
                                label="Rampa"
                                select
                                value={id_ramp}
                                onChange={(e) => { setId_ramp(e.target.value) }}
                                size="small"
                                name="poradie"
                            >
                                {
                                    rampy.map((rampa) => (
                                        <MenuItem key={rampa.id} value={rampa.id}>
                                            {rampa.nazov}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </MuiGrid>

                    </MuiGrid>




                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        console.log(data_s)
                        let auta = data_s
                        let status_auta = 0
                        let i = 0
                        while (i < auta.length) {
                            if (auta[i].id === id_car) {
                                status_auta = auta[i].status
                            }
                            i++
                        }
                        if (status_auta === 2) {
                            setOpen_medzi(true)
                        } else {
                            handleClose_ramp()
                        }

                    }} >
                        Vyvolat
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={open_medzi}
                onClose={() => { setOpen_medzi(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>{"Řidič již byl vyvolan. Chcete ho vyvolat znova?"}</span>
                </DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose_ramp} >
                        Ano
                    </Button>
                    <Button onClick={() => { setOpen_medzi(false) }} >
                        Ne
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={open_opustenie}
                onClose={() => {
                    setOpen_opustenie(false)
                    setVaha(0)
                    setVaha_out(0)
                    setPoznamkaSklad('')
                    setPaletaOut('')
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>Opuštění vratnice</span>
                </DialogTitle>

                <DialogContent>
                    <MuiGrid container spacing={0.15}>
                        <MuiGrid item xs={6.5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Palety</Box>
                        </MuiGrid>
                        <MuiGrid item xs={5}>
                            <Box sx={{ color: 'text.secondary', mt: 3 }}>{paleta_out}</Box>
                        </MuiGrid>
                        <MuiGrid item xs={6.5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Poznamka</Box>
                        </MuiGrid>
                        <MuiGrid item xs={5}>
                            <Box sx={{ color: 'text.secondary', mt: 3 }}>{poznamka_sklad}</Box>
                        </MuiGrid>
                        <MuiGrid item xs={6.5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Vaha pri registraci</Box>
                        </MuiGrid>
                        <MuiGrid item xs={5}>
                            <Box sx={{ color: 'text.secondary', mt: 3 }}>{vaha} kg </Box>
                        </MuiGrid>
                        <MuiGrid item xs={6.5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Vaha po opuštění</Box>
                        </MuiGrid>
                        <MuiGrid item xs={3}>

                            <TextField
                                margin="normal"
                                value={vaha_out}
                                label="Vaha"
                                size="small"
                                name="poradie"
                                type="number"
                                onChange={(e) => { setVaha_out(e.target.value) }}
                                onFocus={(e) => { e.target.select() }}

                            />
                        </MuiGrid>
                        <MuiGrid item xs={2}>
                            <Box sx={{ color: 'text.secondary', mt: 3 }}> kg</Box>
                        </MuiGrid>
                    </MuiGrid>


                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose_opustenie} >
                        Opouštění vratnice
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open_odbaveni}
                onClose={() => { setOpen_odbaveni(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span>{"Odbaveni:"}</span>
                </DialogTitle>
                <DialogContent>

                    <MuiGrid container spacing={0.15}>
                        {palety_odbaveni.map((input, index) => {
                            return (
                                <>
                                    <MuiGrid item xs={4} >
                                        <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>{index === 0 ? "Počet palet/krt" : ""}</Box>
                                    </MuiGrid>
                                    <MuiGrid item xs={4}>

                                        <TextField
                                            margin="normal"
                                            value={input.pocet}
                                            label="Počet palet"
                                            size="small"
                                            name="pocet"
                                            type="number"
                                            onChange={event => handleFormChange(index, event)}
                                            //onChange={(e) => { setPocet_out(e.target.value) }}
                                            onFocus={(e) => { e.target.select() }}

                                        />

                                    </MuiGrid>
                                    <MuiGrid item xs={3}>

                                        <TextField
                                            margin="normal"
                                            label="Typ"
                                            select
                                            value={input.jedn}
                                            onChange={event => handleFormChange(index, event)}
                                            //onChange={(e) => { setCurrency_out(e.target.value) }}
                                            size="small"
                                            name="jedn"

                                        //onChange={handleChange}


                                        >
                                            <ListSubheader>1. přepravky</ListSubheader>
                                            <MenuItem value="E1"> E1</MenuItem>
                                            <MenuItem value="E2"> E2</MenuItem>
                                            <MenuItem value="EPS 186"> EPS 186</MenuItem>
                                            <MenuItem value="EPS"> EPS</MenuItem>
                                            <MenuItem value="HVH"> HVH</MenuItem>
                                            <MenuItem value="HVM"> HVM</MenuItem>
                                            <MenuItem value="HVL"> HVL</MenuItem>
                                            <ListSubheader>2. palety</ListSubheader>
                                            <MenuItem value="EPAL"> EPAL</MenuItem>
                                            <MenuItem value="IPAL"> IPAL</MenuItem>
                                            <MenuItem value="H1"> H1</MenuItem>
                                            <ListSubheader>3. ostatní</ListSubheader>
                                            <MenuItem value="IBC"> IBC</MenuItem>
                                            <MenuItem value="jiné"> jiné</MenuItem>
                                        </TextField>

                                    </MuiGrid>
                                    <MuiGrid item xs={1}>
                                        {
                                            index === 0
                                                ? <></>
                                                : <button style={{ marginTop: 20 }} onClick={() => removeFields(index)}>-</button>
                                        }


                                    </MuiGrid>
                                </>
                            )
                        })}

                        <MuiGrid item xs={4} >

                        </MuiGrid>
                        <MuiGrid item xs={4}>

                            <button onClick={addFields}>+</button>

                        </MuiGrid>
                        <MuiGrid item xs={4}>

                        </MuiGrid>

                        <MuiGrid item xs={4} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Poznámka</Box>
                        </MuiGrid>
                        <MuiGrid item xs={8}>

                            <TextField
                                margin="normal"
                                value={pozn_odbav}
                                label="Poznámka"
                                size="small"
                                name="poznamka_ridic"
                                style={{ width: 300 }}
                                maxLength={100}
                                onChange={(e) => { setPozn_odb(e.target.value) }}


                            />
                        </MuiGrid>
                    </MuiGrid>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose_odbaveni} >
                        Odbavit
                    </Button>


                </DialogActions>
            </Dialog>

            <Dialog
                open={open_vyr}
                onClose={() => { setOpen_vyr(false)}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Požadavek pro sklad:"}
                </DialogTitle>
                <DialogContent>

                    <MuiGrid container spacing={0.15}>
                        
                        
                  
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 0, fontWeight: 'bold' }}>Telefon / Telephone</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>
                            <PhoneInput
                                value={tel}
                                onChange={phone => setTel(phone)}
                                country={"cz"}
                                regions="europe"
                                preferredCountries={["cz", "sk", "pl", "de", "at", "hu"]}
                            />

                        </MuiGrid>
                        

                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 0, fontWeight: 'bold' }}>Počet palet/krt / No. Of pallets</Box>
                        </MuiGrid>
                        <MuiGrid item xs={3}>

                            <TextField
                                margin="normal"
                                value={pocet}
                                label="Počet palet / No. Of pallets"
                                size="small"
                                name="poradie"
                                type="number"
                                onChange={(e) => { setPocet(e.target.value) }}
                                onFocus={(e) => { e.target.select() }}

                            />

                        </MuiGrid>
                        <MuiGrid item xs={2}>

                            <TextField
                                margin="normal"
                                label="Typ/Type"
                                select
                                value={currency}
                                onChange={(e) => { setCurrency(e.target.value) }}
                                size="small"
                                name="poradie"

                            //onChange={handleChange}


                            >
                                <MenuItem key={currencies[0].value} value={currencies[0].value}>
                                    {currencies[0].label}
                                </MenuItem >
                                <MenuItem key={currencies[1].value} value={currencies[1].value}>
                                    {currencies[1].label}
                                </MenuItem >
                                <MenuItem key={currencies[2].value} selected value={currencies[2].value}>
                                    {currencies[2].label}
                                </MenuItem >

                            </TextField>

                        </MuiGrid>
                       

                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Teplotní režim /<br /> Temperature mode</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={tepl}
                                onChange={(e) => { setTepl(e.target.value) }}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Chlazené do 8°C / Refrigerated" />
                                <FormControlLabel value="2" control={<Radio />} label="Mrazené -18°C / Frozen" />
                                <FormControlLabel value="3" control={<Radio />} label="Suche / Dry" />
                                <FormControlLabel value="4" control={<Radio />} label="Kombinace / Combination" />


                            </RadioGroup>
                        </MuiGrid>
                        <MuiGrid item xs={5} >
                            <Box sx={{ color: 'text.secondary', mt: 3, fontWeight: 'bold' }}>Druh zboží</Box>
                        </MuiGrid>
                        <MuiGrid item xs={7}>

                            <TextField
                                margin="normal"
                                value={poznamka_ridic}
                                label="Druh zboží"
                                size="small"
                                style={{ width: 300 }}
                                name="poznamka_ridic"
                                maxLength={100}
                                onChange={(e) => { setPoznamkaRidic(e.target.value) }}


                            />
                        </MuiGrid>
                    </MuiGrid>




                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', marginBottom: 5 }}>
                    {
                        id > 0
                            ? <Button
                                disabled={!checked && ldata.UserData.typ === "R"}
                                variant="contained"
                                onClick={async () => {
                                    console.log(v_n.length)
                                    await axios.put(vyrobaPostUrl + "/" + id, {
                                        telefon: tel,
                                        in_paleta_pocet: parseInt(pocet),
                                        in_palety_jedn: currency,
                                        tepl_rezim: tepl,
                                        poznamka_ridic: poznamka_ridic,
                                        userType: ldata.userType
                                    }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
                                        .then(function (response) {
                                            setOpen_ok_zmena(true)
                                            console.log(response)
                                            getDataVyroba()

                                        })
                                        .catch(function (error) {
                                            setOpen_err(true)
                                            setErr_msg(error.response.data)

                                        });

                                }

                                }

                            >
                                Opravit
                            </Button>
                            : <Button
                                disabled={!checked && ldata.UserData.typ === "R"}
                                variant="contained"
                                onClick={async () => {
                                    console.log(v_n.length)
                                    console.log(tepl)
                                    await axios.post(vyrobaPostUrl, {
                                        telefon: tel,
                                        in_paleta_pocet: parseInt(pocet),
                                        in_palety_jedn: currency,
                                        tepl_rezim: tepl,
                                        poznamka_ridic: poznamka_ridic,
                                        userType: ldata.userType
                                    }, { headers: { Authorization: 'Bearer ' + ldata.Token } })
                                        .then(function (response) {
                                            setOpen_ok(true)
                                            console.log(response)
                                            getDataVyroba()

                                        })
                                        .catch(function (error) {
                                            setOpen_err(true)
                                            setErr_msg(error.response.data)

                                        });

                                }

                                }

                            >
                                Registrovat / Register
                            </Button>
                    }


                </DialogActions>
            </Dialog>
        </div>
    );
}

export default App;