import React from 'react';
import Login from "./pages/Login/loginPage";
import Vysyp from "./pages/vysyp/vysypPage";
import Admin from "./pages/Admin/admin";
import { BrowserRouter, Switch, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";


function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={<Login />} />
                <Route path="/vysyp" element={<Vysyp />} />
                <Route path="/admin" element={<Admin />} />

            </Routes>
        </BrowserRouter>


    );
}

export default App;
