const serverUrl = process.env.NODE_ENV === "production" ? `${process.env.REACT_APP_PROD_URL}` : `${process.env.REACT_APP_DEV_URL}`;


export const loginUrl = serverUrl + `api/vysyp/login`;

export const registraciaUrl = serverUrl + `api/vysyp/registracia`;

export const skladUrl = serverUrl + `api/vysyp/listSklad`;

export const vyvolanieUrl = serverUrl + `api/vysyp/vyvolanie`;

export const archiveUrl = serverUrl + `api/vysyp/listSkladArchive`;

export const vratnicaUrl = serverUrl + `api/vysyp/listVratnica`;

export const notifikaciaUrl = serverUrl + `hubs/notification`;

export const vratnicaArchivUrl = serverUrl + `api/vysyp/listVratnicaArchive`;

export const opustenieUrl = serverUrl + `api/vysyp/vratnica`;

export const odbavenieUrl = serverUrl + `api/vysyp/odbavenie`;

export const spzUrl = serverUrl + `api/vysyp/historia`;

export const vyrobaUrl = serverUrl + `api/vysyp/listVyroba`;

export const vyrobaArchivUrl = serverUrl + `api/vysyp/listVyrobaArchiv`;

export const vyrobaPostUrl = serverUrl + `api/vysyp/registraciaVyroba`;

export const usersUrl = serverUrl + `api/admin/users`;

